<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title>
            <img v-if="productcategory.prod_c_icone" :src="`${base_url}upload/product_category/i/${productcategory.prod_c_icone}`" width="40">
            <img v-if="!productcategory.prod_c_icone" :src="`${base_url}upload/imagem.png`" width="40">
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <h1  class="lime--text lighten-1">{{ productcategory.prod_c_nome }}</h1>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey" dark class="mb-2" :to="{name: 'product-categories'}">
                <v-icon dark>keyboard_arrow_left</v-icon> Voltar
            </v-btn>
        </template>
    </v-toolbar>

    <table id="table-detalhe" class="table table-bordered table-condensed">
        <tbody>
            <tr>
                <th width="10%">Código:</th>
                <td width="90%">{{ productcategory.prod_c_codigo }}</td>
            </tr>
            <tr>
                <th width="10%">Nome:</th>
                <td width="90%">{{ productcategory.prod_c_nome }}</td>
            </tr>
            <tr v-if="productcategory.departamento">
                <th width="10%">Departamento:</th>
                <td width="90%">{{ productcategory.departamento.prod_dp_nome }}</td>
            </tr>
            <tr>
                <th width="10%">Link:</th>
                <td width="90%">{{ productcategory.prod_c_link }}</td>
            </tr>
            <tr>
                <th width="10%">Ordem:</th>
                <td width="90%">{{ productcategory.prod_c_ordem }}</td>
            </tr>
            <tr>
                <th width="10%">Status:</th>
                <td width="90%">{{ (productcategory.prod_c_status == '1') ? 'Ativo' : 'Inativo' }}</td>
            </tr>
            <tr>
                <th width="10%">Mostrar menu:</th>
                <td width="90%">{{ (productcategory.prod_c_show_menu == '1') ? 'Ativo' : 'Inativo' }}</td>
            </tr>
            <tr>
                <th width="10%">Icone:</th>
                <td v-if="productcategory.prod_c_icone" width="10%">
                    <img :src="`${base_url}upload/product_category/i/${productcategory.prod_c_icone}`" width="60">
                </td>
                <td v-if="!productcategory.prod_c_icone" width="10%">
                    <img :src="`${base_url}upload/imagem.png`">
                </td>
            </tr>
            <tr>
                <th width="10%">Banner:</th>
                <td v-if="productcategory.prod_c_banner" width="10%">
                    <img :src="`${base_url}upload/product_category/b/${productcategory.prod_c_banner}`" width="400">
                </td>
                <td v-if="!productcategory.prod_c_banner" width="10%">
                    <img :src="`${base_url}upload/imagem.png`">
                </td>
            </tr>
        </tbody>
    </table>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    name: "DetailEmployeeComponent",
    created() {
        this.loadProductCategory()
    },
    props: {
        prod_c_id: {
            require: true
        },
    },
    data() {
        return {
            search: '',
            productcategory: {},
            base_url: URL_BASE
        }
    },
    methods: {
        loadProductCategory() {
            this.$store.dispatch('loadProductCategory', this.prod_c_id)
                .then(response => {
                    this.productcategory = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    }
};
</script>

<style scoped>
.avatar {
    height: 25px;
}

.logo {
    height: 50px;
}
</style>
